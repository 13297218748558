
import { ref, computed } from 'vue';
import CardTable from '@/components/kt-datatable/CardTable.vue';
import {
  tableHeader,
  Colors,
  Statuses,
  convertCamelToTitle,
  filterData,
  changeStatus,
  nonChangeableStatuses,
  getFilteredStatuses,
} from '@/core/data/order';
import moment from 'moment';
import ConfirmModal from '@/components/modals/ConfirmModal.vue';
import { Modal } from 'bootstrap';

export default {
  name: 'OrderQuery',
  props: {
    query: {
      type: String,
      default: '',
    },
    rerenderWithKey: {
      type: Boolean,
      default: false,
    },
    breadcrumbTitle: {
      type: String,
      default: 'Overview',
    },
  },
  components: {
    CardTable,
    ConfirmModal,
  },
  setup(props) {
    const breadcrumbs = ['Order', [`Order ${props.breadcrumbTitle}`]];
    const componentKey = ref(0); // used to force re-rendering of select
    const currentEvent = ref<any>(null);
    const tableRef = ref<any>(null);

    const modalId = computed(() => {
      const id = !nonChangeableStatuses.includes(
        currentEvent.value?.target?.value
      )
        ? '#change-status'
        : '#second-confirmation';

      return id;
    });

    const refreshData = () => {
      tableRef.value?.getData(
        tableRef.value?.currentPage,
        tableRef.value?.rowsPerPage
      );
    };

    const handleChange = (e) => {
      currentEvent.value = e;

      const modal = new Modal(document.getElementById('change-status'));
      modal.toggle();
    };

    const rerender = () => {
      if (props.rerenderWithKey) {
        componentKey.value++;
      } else {
        refreshData();
      }
    };

    return {
      tableHeader,
      breadcrumbs,
      Colors,
      moment,
      Statuses,
      convertCamelToTitle,
      componentKey,
      filterData,
      changeStatus,
      nonChangeableStatuses,
      currentEvent,
      getFilteredStatuses,
      handleChange,
      modalId,
      tableRef,
      rerender,
    };
  },
};
