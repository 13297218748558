import ApiService from '@/core/services/ApiService';

const tableHeader = [
  {
    name: 'Order ID',
    key: 'id',
    sortable: true,
  },
  {
    name: 'Order Type',
    key: 'type',
    sortable: true,
  },
  {
    name: 'Customer',
    key: 'customer',
    sortable: true,
  },
  {
    name: 'Price',
    key: 'amountPrice',
    sortable: true,
  },
  {
    name: 'Date Created',
    key: 'createdAt',
    sortable: true,
  },
  {
    name: 'Date Started',
    key: 'startDate',
    sortable: true,
  },
  {
    name: 'Date Ended',
    key: 'endDate',
    sortable: true,
  },
  {
    name: 'Status',
    key: 'status',
    sortable: true,
  },
  {
    name: 'More Details',
    key: 'details',
    sortable: true,
  },
];

enum Colors {
  WaitPartner = 'primary',
  ReadyForDrive = 'warning',
  Driving = 'info',
  ReturnCar = 'teal',
  Done = 'success',
  Reject = 'danger',
}

enum Statuses {
  WaitPartner = 'Pending',
  ReadyForDrive = 'Accepted',
  Driving = 'On Service',
  ReturnCar = 'Returned',
  Done = 'Completed',
  Reject = 'Canceled',
}

const convertCamelToTitle = (str: string) => {
  if (!str) return '-';
  const result = str.replace(/([A-Z])/g, ' $1');

  return result.charAt(0).toUpperCase() + result.slice(1);
};

const filterData = [
  [
    { name: 'Order Type', key: 'packageName' },
    { name: 'Rent Car Per Day', id: 'rentCarPerDay' },
    { name: 'Rent Car Per Month', id: 'rentCarPerMonth' },
  ],
];

// eslint-disable-next-line @typescript-eslint/ban-types
const changeStatus = async (status: string, id: string, callback: Function) => {
  await ApiService.patch(`order/${id}`, { status });
  callback();
};

const nonChangeableStatuses = ['Done', 'Reject'];

const getFilteredStatuses = (status) => {
  return Object.entries(Statuses).filter((val) => val[0] !== status);
};

const filteredStatuses = Object.entries(Statuses).filter(
  (val) => !nonChangeableStatuses.includes(val[0])
);

export {
  tableHeader,
  Colors,
  Statuses,
  convertCamelToTitle,
  filterData,
  changeStatus,
  nonChangeableStatuses,
  filteredStatuses,
  getFilteredStatuses,
};
